module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NTC Method","short_name":"NTC","start_url":"/","background_color":"#27aae1","theme_color":"#27aae1","display":"minimal-ui","icon":"src/images/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"93ec299966c03f142c3be5c296a8a190"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T8B2CR4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
